import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.sass';
import Image from 'gatsby-image';
import ArticleTitle from '../../../shared/texts/ArticleTitle/ArticleTitle';
import BigArticleTitle from '../../../shared/texts/BigArticleTitle/BigArticleTitle';
import Paragraph from '../../../shared/texts/Paragraph/Paragraph';
import { StaticQuery, graphql } from 'gatsby';
import cs from 'classnames';


class Header extends Component {
  state = {
    isDogActive: false,
    animationActive: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isDogActive: true, animationActive: true });
    }, 1000);
  }

  render() {
    const { data } = this.props;
    const { file: { childImageSharp: { fluid } } } = data;
    const { isDogActive, animationActive } = this.state;
    return (
      <header className={styles.header}>
        <div className={styles.content}>
            <ArticleTitle className={cs(styles.title, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="Wywiady">Wywiady</ArticleTitle>
            <ArticleTitle className={cs(styles.title, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="z&nbsp;hodowcami">z hodowcami</ArticleTitle>
            <BigArticleTitle className={cs(styles.bigTitle, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="Psów&nbsp;rasowych">Psów rasowych</BigArticleTitle>
            <div className={styles.descriptionWrap}>
              <Paragraph className={cs(styles.paragraph, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
                Tutaj posłuchasz unikatowych wywiadów z hodowcami przeróżnych ras psów.
              </Paragraph>
              <Paragraph className={cs(styles.paragraph, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
                Przeczytasz o ich doświadczeniach związanych z posiadaniem ukochanych ras i prowadzeniem hodowli.
              </Paragraph>
            </div>
        </div>
        <div className={cs(styles.dogWrap, { [styles.isActive]: isDogActive })}>
          <Image className={styles.dog} fluid={fluid} alt="Pies"/>
        </div>
      </header>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file (name: {eq: "1dog1"}, childImageSharp: {fixed: {}}){
          childImageSharp{
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)


Header.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
